import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image_01 from '../../../assets/img/PromotionsPage/Banner/image_01.png';
import image_02 from '../../../assets/img/PromotionsPage/Banner/image_02.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.bannerWrap}>
            <div className={classes.bg_01}></div>
            <div className={classes.bg_02}></div>
            <div className={["container", classes.container].join(' ')}>
                <div className={classes.banner}>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <h1 className={[classes.title, 'font-40'].join(' ')}>
                                {t("promo_1")}
                            </h1>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <p>
                                    {t("promo_2")}
                                </p>
                                <p>
                                    {t("promo_3")}
                                </p>
                                <li>
                                    {t("promo_4")}
                                </li>
                                <li>
                                    {t("promo_5")}
                                </li>
                                <li>
                                    {t("promo_6")}
                                </li>
                            </div>
                        </div>
                        <div className={classes.btn}>
                            <Button>{t("btn_open")}</Button>
                        </div>
                        <img className={classes.image_01} src={image_01} alt=''/>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <h2 className={[classes.title, 'font-40', classes.title_02].join(' ')}>
                                {t("promo_7")}
                                <p className={classes.subtitle}>
                                    {t("promo_8")}
                                </p>
                            </h2>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <li>
                                    {t("promo_9")}
                                </li>
                                <li>
                                    {t("promo_10")}
                                </li>
                                <li>
                                    {t("promo_11")}
                                </li>
                                <li>
                                    {t("promo_12")}
                                </li>
                            </div>
                        </div>
                        <div className={classes.btn}>
                            <Button>{t("btn_open")}</Button>
                        </div>
                        <img className={classes.image_02} src={image_02} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;

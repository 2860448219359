import React from "react";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/img-new-2.png";
import img2 from "../../../assets/img/LearnPage/1/2.png";
import img3 from "../../../assets/img/LearnPage/1/3.png";
import img4 from "../../../assets/img/LearnPage/1/4.png";
import Button from "../../GlobalComponents/Button/Button";
import {useTranslation} from "react-i18next";
const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h2 className={[classes.firstRowLeftTitle, "font-40"].join(" ")}>
                <span>{t("learn_1")}</span>
              </h2>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t("learn_2")}
              </p>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t("learn_3")}
              </p>
              <div className={[classes.firstRowLeftBtn, ""].join(" ")}>
                <Button>{t("btn_trade")}</Button>
              </div>
            </div>
            <div className={[classes.firstRowRight, ""].join(" ")}>
              <div className={[classes.firstRowRightImgs, ""].join(" ")}>
                <div className={[classes.firstRowRightImgsImg1, ""].join(" ")}>
                  <img src={img1} alt="Education" />
                </div>
                <div className={[classes.firstRowRightImgsImg2, ""].join(" ")}>
                  <img src={img2} alt="Education" />
                </div>
                <div className={[classes.firstRowRightImgsImg3, ""].join(" ")}>
                  <img src={img3} alt="Education" />
                </div>
                <div className={[classes.firstRowRightImgsImg4, ""].join(" ")}>
                  <img src={img4} alt="Education" />
                </div>
              </div>
              <div className={[classes.mob, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;

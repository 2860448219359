import React from "react";
import classes from "./Four.module.scss";

import img1 from "../../../assets/img/AboutPage/5/1.png";
import img2 from "../../../assets/img/AboutPage/5/2.png";
import img3 from "../../../assets/img/AboutPage/5/3.png";
import {useTranslation, Trans} from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, "mt"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <h2 className={[classes.threeTitle, "font-40"].join(" ")}>
          <Trans>about_16</Trans>
        </h2>

        <div className={[classes.threeRow, ""].join(" ")}>
          <div className={[classes.threeRowColumnInfo, ""].join(" ")}>
            <h2
              className={[classes.threeRowColumnInfoTitle, "font-20"].join(" ")}
            >
              {t("about_17")}
            </h2>
            <p
              className={[classes.threeRowColumnInfoText, "font-20"].join(" ")}
            >
              {t("about_18")}
            </p>
          </div>
          <div className={[classes.threeRowImg, ""].join(" ")}>
            <img src={img1} alt="" />
          </div>
        </div>
        <div className={[classes.threeRow, ""].join(" ")}>
          <div className={[classes.threeRowImg, ""].join(" ")}>
            <img src={img2} alt="" />
          </div>
          <div className={[classes.threeRowColumnInfo, ""].join(" ")}>
            <h2
              className={[classes.threeRowColumnInfoTitle, "font-20"].join(" ")}
            >
              {t("about_19")}
            </h2>
            <p
              className={[classes.threeRowColumnInfoText, "font-20"].join(" ")}
            >
              {t("about_20")}
            </p>
          </div>
        </div>
        <div className={[classes.threeRow, ""].join(" ")}>
          <div className={[classes.threeRowColumnInfo, ""].join(" ")}>
            <h2
              className={[classes.threeRowColumnInfoTitle, "font-20"].join(" ")}
            >
              {t("about_21")}
            </h2>
            <p
              className={[classes.threeRowColumnInfoText, "font-20"].join(" ")}
            >
              {t("about_22")}
            </p>
          </div>
          <div className={[classes.threeRowImg, ""].join(" ")}>
            <img src={img3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;

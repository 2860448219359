import React from "react";
import classes from './MarketOrders.module.scss';
import { useTranslation } from "react-i18next";
import woman from '../../../assets/img/img-new-3.png';
import item_01 from '../../../assets/img/TradingPage/LegendaryExecution/item_03.png';
import item_02 from '../../../assets/img/TradingPage/MarketOrders/item_02.png';
import item_03 from '../../../assets/img/TradingPage/MarketOrders/item_03.png';
import item_04 from '../../../assets/img/TradingPage/MarketOrders/item_04.png';
import item_05 from '../../../assets/img/TradingPage/LegendaryExecution/item_02.png';
import item_06 from '../../../assets/img/TradingPage/LegendaryExecution/item_02.png';

const MarketOrders = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.legendaryExecution}>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <h4 className={classes.title}>
                        {t("trade_166")}
                    </h4>
                    <p>
                        {t("trade_167")}
                    </p>
                    <h4 className={classes.title}>
                        {t("trade_168")}
                    </h4>
                    <p>
                        {t("trade_169")}
                    </p>
                    <h4 className={classes.title}>
                        {t("trade_170")}
                    </h4>
                    <p>
                        {t("trade_171")}
                    </p>
                    <h4 className={classes.title}>
                        {t("trade_172")}
                    </h4>
                    <p>
                        {t("trade_173")}
                    </p>
                </div>
                <div className={classes.images}>
                    <img className={classes.woman} src={woman} alt=''/>
                    <div className={classes.item_01}>
                        <img src={item_01} alt=''/>
                    </div>
                    <div className={classes.item_02}>
                        <img src={item_02} alt=''/>
                    </div>
                    <div className={classes.item_03}>
                        <img src={item_03} alt=''/>
                    </div>
                    <div className={classes.item_04}>
                        <img src={item_04} alt=''/>
                    </div>
                    <div className={classes.item_05}>
                        <img src={item_05} alt=''/>
                    </div>
                    <div className={classes.item_06}>
                        <img src={item_06} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MarketOrders;

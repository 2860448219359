import React from "react";
import classes from './TermsAndConditions.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/PromotionsPage/TermsAndConditions/image_01.png';
import image_02 from '../../../assets/img/PromotionsPage/TermsAndConditions/image_02.png';
import image_03 from '../../../assets/img/PromotionsPage/TermsAndConditions/image_03.png';
import image_04 from '../../../assets/img/PromotionsPage/TermsAndConditions/image_04.png';

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: image_01,
            text: 'promo_13',
        },
        {
            image: image_02,
            text: 'promo_14',
        },
        {
            image: image_03,
            text: 'promo_15',
        },
        {
            image: image_04,
            text: 'promo_16',
        },
    ];
    return (
        <section className="container mt">
            <div className={classes.termsAndConditions}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t("promo_17")}
                </h2>
                <h4 className={[classes.subtitle, 'font-40'].join(' ')}>
                    <span>
                        {t("promo_7")}
                    </span>
                </h4>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t("promo_18")}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                <Trans>
                                    {card.text}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TermsAndConditions;

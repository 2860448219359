import React, { useState } from "react";
import classes from "./Faq.module.scss";
import {useTranslation, Trans} from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      title: 'promo_66',
      text: 'promo_67',
    },
    {
      title: 'promo_68',
      text: 'promo_69',
    },
    {
      title: 'promo_70',
      text: 'promo_71',
    }
  ];
  const [currentTab, setCurrentTab] = useState(-1);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(-1);
      return;
    }
    setCurrentTab(index);
  };
  return (
    <div className={[classes.faq, ""].join(" ")}>
      <section className="mt container">
        <div className={classes.faqWrap}>
          <h2 className={[classes.title, "font-40"].join(" ")}>{t("promo_72")}</h2>
          <div className={classes.tabs}>
            {tabs.map((tab, index) =>
              <div
                className={`${classes.tab} ${currentTab === index && classes.tabActive} no-select font-20`}
                onClick={() => openTab(index)}
                key={index}
              >
                <div className={classes.tabHeader}>
                  <div className={classes.tabHeaderText}>
                    <Trans>{tab.title}</Trans>
                  </div>
                  <div className={classes.tabStatus}></div>
                </div>
                <div className={classes.tabContent}>
                  <p className="font-18">
                    <Trans>{tab.text}</Trans>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;

import React, { useEffect, useRef } from "react";
import classes from "./SixReasons.module.scss";
import icon_01 from "../../../assets/img/HomePage/2/1.png";
import icon_02 from "../../../assets/img/HomePage/2/2.png";
import icon_03 from "../../../assets/img/HomePage/2/3.png";
import icon_04 from "../../../assets/img/HomePage/2/4.png";
import icon_05 from "../../../assets/img/HomePage/2/5.png";
import icon_06 from "../../../assets/img/HomePage/2/6.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const cards = [
  {
    icon: icon_01,
    title: "",
    text: "main_15",
  },
  {
    icon: icon_02,
    title: "",
    text: "main_16",
  },
  {
    icon: icon_03,
    title: "",
    text: "main_17",
  },
  {
    icon: icon_04,
    title: "",
    text: "main_18",
  },
  {
    icon: icon_05,
    title: "",
    text: "main_19",
  },
  {
    icon: icon_06,
    title: "",
    text: "main_20",
  },
];

const Card = ({ card }) => {
  return (
    <div className={[classes.card, "font-20"].join(" ")}>
      <img className={classes.cardIcon} src={card.icon} alt="" />
      <h4 className={classes.cardTitle}>{card.title}</h4>
      <p className={classes.cardText}>
        <Trans>{card.text}</Trans>
      </p>
    </div>
  );
};

const SixReasons = () => {
  const { t } = useTranslation();
  const lineWrapRef_01 = useRef(null);
  const lineWrapRef_02 = useRef(null);
  const lineRef_01 = useRef(null);
  const lineRef_02 = useRef(null);
  const bodyRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        lineWrapRef_01.current,
        {
          x: -350,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: bodyRef.current,
            end: "+=400px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().fromTo(
        lineWrapRef_02.current,
        {
          x: 350,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: bodyRef.current,
            end: "+=400px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().to(lineRef_01.current, {
        x: 350,
        scrollTrigger: {
          trigger: bodyRef.current,
          start: "top top",
          end: "+=300px",
          scrub: 3,
        },
      });
      gsap.timeline().to(lineRef_02.current, {
        x: -350,
        scrollTrigger: {
          trigger: bodyRef.current,
          start: "top top",
          end: "+=300px",
          scrub: 3,
        },
      });
    }
  }, []);
  return (
    <section className={classes.sixReasons}>
      <div className="container mt">
        <h2 className={[classes.title1, "font-40"].join(" ")}>
          <Trans>
            {t('gm_title')}
          </Trans>
        </h2>
        <div ref={bodyRef} className={classes.cards}>
          <div ref={lineWrapRef_01} className={classes.lineWrap}>
            <div ref={lineRef_01} className={classes.line}>
              {cards.map((card, index) => {
                if (index < 3) {
                  return <Card key={index} card={card} />;
                }
              })}
            </div>
          </div>
          <div ref={lineWrapRef_02} className={classes.lineWrap}>
            <div ref={lineRef_02} className={classes.line}>
              <div></div>
              {cards.map((card, index) => {
                if (index >= 3) {
                  return <Card key={index} card={card} />;
                }
              })}
            </div>
          </div>
        </div>
        <div className={classes.cardsPlate}>
          {cards.map((card, index) => (
            <Card card={card} key={index} />
          ))}
        </div>
      </div>
      <div className={classes.swiperContainer}>
        <div className={classes.swiperWr}>
          <Swiper
            modules={[Pagination]}
            className="sixReasonsSwiper"
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index} className="sixReasonsSwiperSlide">
                <Card card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SixReasons;

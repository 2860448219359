import React, { useState } from "react";
import classes from "./Faq.module.scss";
import {useTranslation, Trans} from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(0);
      return;
    }
    setCurrentTab(index);
  };
  return (
    <div className={[classes.faq, ""].join(" ")}>
      <section className="mt container">
        <div className={classes.faqWrap}>
          <h2 className={[classes.title, "font-40"].join(" ")}>{t("main_65")}</h2>
          <div className={classes.tabs}>
            <div
              className={`${classes.tab} ${
                currentTab === 1 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(1)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_66")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_67")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 2 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(2)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_68")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_69")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 3 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(3)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_70")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_71")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 4 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(4)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_72")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_73")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 6 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(6)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_76")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_77")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 7 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(7)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_78")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_79")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 8 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(8)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_80")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_81")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 9 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(9)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_82")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_83")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 10 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(10)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("main_84")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("main_85")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;

import React from "react";
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import "./styles.scss";
import classes from "./Slider.module.scss"

export const Slide = React.memo(function (StackedCarouselSlideProps) {
    const {
        data,
        dataIndex,
        isCenterSlide,
        swipeTo,
        slideIndex
    } = StackedCarouselSlideProps;

    const coverImage = data[dataIndex].image;
    const text = data[dataIndex].text;
    const title = data[dataIndex].title;

    return (
        <div className="card-card" draggable={false}>
            <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
                <div
                    className="card-overlay fill"
                    onClick={() => {
                        if (!isCenterSlide) swipeTo(slideIndex);
                    }}
                />
            </div>
            <div style={{ width: '100%' }} className="detail fill">
                <div className="discription">
                    <div className={[classes.slide, ""].join(" ")}>
                        <div className={[classes.slideImg, ""].join(" ")}>
                            <img src={coverImage} alt="" />
                        </div>
                        <div className={[classes.slideTitle, "font-20"].join(" ")}>
                            {title}
                        </div>
                        <div className={[classes.slideText, "font-20"].join(" ")}>
                            {text}
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
});

export default Slide;
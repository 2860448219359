import classes from "./First.module.scss";
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from "../../GlobalComponents/Button/Button";

import Tab1 from "./Tab1/Tab1";
import Tab2 from "./Tab2/Tab2";
import Tab3 from "./Tab3/Tab3";
import Tab4 from "./Tab4/Tab4";

const First = () => {
  const { i18n } = useTranslation();
  const [, setForceUpdate] = useState();

  useEffect(() => {
    const languageChanged = () => {
      setForceUpdate({});
    };

    i18n.on('languageChanged', languageChanged);

    return () => {
      i18n.off('languageChanged', languageChanged);
    };
  }, [i18n]);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className={`container ${classes.firstContainer}`}>
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h1
                  className={[classes.firstRowLeftTitle, "font-40"].join(
                      " "
                  )}
              >
                <Trans>main_1</Trans>
              </h1>
              <p
                  className={[classes.firstRowLeftText, "font-20"].join(
                      " "
                  )} 
              > 
                <Trans>main_2</Trans>
              </p>
              <div className={[classes.firstRowLeftBtn, ""].join(" ")}>
                <Button>Trade Now</Button>
              </div>
            </div>
            <Tab1 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;

import React, { useState } from "react";
import classes from "./Faq.module.scss";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

const Faq = ({ active, setActive }) => {
  const { t } = useTranslation();
  const tabs = [
    [
      {
        title: 'promo_81',
        text: 'promo_82',
      },
      {
        title: 'promo_83',
        text: 'promo_84',
      },
      {
        title: 'promo_85',
        text: 'promo_86',
      },
      {
        title: 'promo_87',
        text: 'promo_88',
      },
      {
        title: 'promo_89',
        text: 'promo_90',
      },
      {
        title: 'promo_91',
        text: 'promo_92',
      },
      {
        title: 'promo_93',
        text: 'promo_94',
      },
      {
        title: 'promo_95',
        text: 'promo_96',
      },
      {
        title: 'promo_97',
        text: 'promo_98',
      },
      {
        title: 'promo_99',
        text: 'promo_100',
      },
    ],
    [
      {
        title: 'promo_101',
        text: 'promo_102',
      },
      {
        title: 'promo_103',
        text: 'promo_104',
      },
      {
        title: 'promo_105',
        text: 'promo_106',
      },
      {
        title: 'promo_107',
        text: 'promo_108',
      },
      {
        title: 'promo_109',
        text: 'promo_110',
      },
      {
        title: 'promo_111',
        text: 'promo_112',
      },
      {
        title: 'promo_113',
        text: 'promo_114',
      },
      {
        title: 'promo_115',
        text: 'promo_116',
      },
      {
        title: 'promo_117',
        text: 'promo_118',
      },
    ],
    [
      {
        title: 'promo_119',
        text: 'promo_120',
      },
      {
        title: 'promo_121',
        text: 'promo_122',
      },
      {
        title: 'promo_123',
        text: 'promo_124',
      },
      {
        title: 'promo_125',
        text: 'promo_126',
      },
      {
        title: 'promo_127',
        text: 'promo_128',
      },
    ],
    [
      {
        title: 'promo_129',
        text: 'promo_130',
      },
      {
        title: 'promo_131',
        text: 'promo_132',
      },
      {
        title: 'promo_133',
        text: 'promo_134',
      },
      {
        title: 'promo_135',
        text: 'promo_136',
      },
      {
        title: 'promo_137',
        text: 'promo_138',
      },
      {
        title: 'promo_141',
        text: 'promo_142',
      },
      {
        title: 'promo_143',
        text: 'promo_144',
      },
      {
        title: 'promo_145',
        text: 'promo_146',
      },
      {
        title: 'promo_147',
        text: 'promo_148',
      },
    ],
    [
      {
        title: 'promo_149',
        text: 'promo_150',
      },
      {
        title: 'promo_151',
        text: 'promo_152',
      },
      {
        title: 'promo_153',
        text: 'promo_154',
      },
      {
        title: 'promo_155',
        text: 'promo_156',
      },
      {
        title: 'promo_157',
        text: 'promo_158',
      },
      {
        title: 'promo_159',
        text: 'promo_160',
      },
      {
        title: 'promo_161',
        text: 'promo_162',
      },
      {
        title: 'promo_163',
        text: 'promo_164',
      },
      {
        title: 'promo_165',
        text: 'promo_166',
      },
    ],
    [
      {
        title: 'promo_167',
        text: 'promo_168',
      },
      {
        title: 'promo_169',
        text: 'promo_170',
      },
      {
        title: 'promo_171',
        text: 'promo_172',
      },
      {
        title: 'promo_173',
        text: 'promo_174',
      },
      {
        title: 'promo_175',
        text: 'promo_176',
      },
      {
        title: 'promo_177',
        text: 'promo_178',
      },
      {
        title: 'promo_179',
        text: 'promo_180',
      },
      {
        title: 'promo_181',
        text: 'promo_182',
      },
      {
        title: 'promo_183',
        text: 'promo_184',
      },
      {
        title: 'promo_185',
        text: 'promo_186',
      },
    ],
  ];

  const titles = [
    'promo_187',
    'promo_188',
    'promo_189',
    'promo_190',
    'promo_191',
    'promo_192',
  ];
  const [currentTab, setCurrentTab] = useState(-1);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(-1);
      return;
    }
    setCurrentTab(index);
  };
  useEffect(() => {
    setCurrentTab(-1);
  }, [active]);
  return (
    <div className={[classes.faq, ""].join(" ")}>
      <section className="mt container">
        <div className={classes.faqWrap}>
          <h2 className={[classes.title, "font-40"].join(" ")}>
            <Trans>{titles[active]}</Trans>
          </h2>
          <div className={classes.tabs}>
            {tabs[active].map((tab, index) =>
              <div
                className={`${classes.tab} ${currentTab === index && classes.tabActive} no-select font-20`}
                onClick={() => openTab(index)}
                key={index}
              >
                <div className={classes.tabHeader}>
                  <div className={classes.tabHeaderText}>
                    <Trans>{tab.title}</Trans>
                  </div>
                  <div className={classes.tabStatus}></div>
                </div>
                <div className={classes.tabContent}>
                  <p className="font-18">
                    <Trans>{tab.text}</Trans>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;

import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation, Trans } from "react-i18next";
import image_01 from '../../../assets/img/TradingPage/UnderBanner/image_01.png';
import image_02 from '../../../assets/img/TradingPage/UnderBanner/image_02.png';
import image_03 from '../../../assets/img/TradingPage/UnderBanner/image_03.png';
import image_04 from '../../../assets/img/TradingPage/UnderBanner/image_04.png';
import image_05 from '../../../assets/img/TradingPage/UnderBanner/image_05.png';

const cards = [
    {
        image: image_01,
        text: "main_88",
    },
    {
        image: image_02,
        text: 'main_89',
    },
    {
        image: image_03,
        text: 'main_90',
    },
    {
        image: image_04,
        text: 'main_91',
    },
    {
        image: image_05,
        text: 'main_92',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.underBanner}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img src={card.image} alt=''/>
                            <p className={[classes.text, 'font-20'].join(' ')}>
                                <Trans>{t(card.text)}</Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;

import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={[classes.footerWrap, 'mt', 'container'].join(' ')}>
      <div className={classes.footer}>
        <div className={classes.info}>
          <h5 className={classes.title}>
            {t("about_53")}
          </h5>
          <p>
            {t("about_54")}
          </p>
          <h5 className={classes.title}>
            {t('about_ab_01')}
          </h5>
          <p>
            {t('about_ab_02')}
          </p>
          <h5 className={classes.title}>
            {t("about_55")}
          </h5>
          <p>
            {t("about_56")}
          </p>
          <p>
            {t("about_59")}
          </p>
        </div>
        <div className={classes.docs}>
          <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
            {t("about_60")}
          </a>
          <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
            {t("about_61")}
          </a>
          <a href="/docs/AML_POLICY.pdf" target="_blank">
            {t("about_62")}
          </a>
          {/* <a href="/docs/Cysec_Leverus_EN.pdf" target="_blank">
            {t("about_66")}
          </a> */}
        </div>
        <div className={classes.contacts}>
          <p>
            {t("about_63")}
          </p>
          <div className={classes.contactUs}>
            <p>
              {t("about_64")}
            </p>
            <a href="tel:+971521733131">
              +97 1521 733131
            </a>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
          </div>
        </div>
      </div>
      <p className={classes.rights}>
        {t("about_65")}
      </p>
    </footer>
  );
};

export default Footer;

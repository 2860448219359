import React from "react";
import classes from "./Four.module.scss";
import img1 from "../../../assets/img/HomePage/4/1.png";
import img2 from "../../../assets/img/HomePage/4/2.png";
import img3 from "../../../assets/img/HomePage/4/3.png";
import img4 from "../../../assets/img/HomePage/4/4.png";
import img5 from "../../../assets/img/HomePage/4/5.png";
import img6 from "../../../assets/img/HomePage/4/6.png";
import img7 from "../../../assets/img/HomePage/4/7.png";
import { useTranslation, Trans } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mt"].join(" ")}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_23")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_24</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_25")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_26</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_27")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_28</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img4} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_29")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_30</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img5} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_31")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_32</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img6} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_33")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_34</Trans>
              </p>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnImg, ""].join(" ")}>
                <img src={img7} alt="" />
              </div>
              <h2 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                {t("main_35")}
              </h2>
              <p className={[classes.fourRowColumnText, "font-20"].join(" ")}>
                <Trans>main_36</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;

import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Tabs = () => {
    const { t } = useTranslation();
    const tabs = [
        {
            title: t("trade_66"),
            rows: [
                [
                    'trade_67',
                    'trade_68',
                    'trade_69',
                    'trade_70',
                    'trade_71',
                    'trade_72',
                    'trade_73',
                ],
                [
                    'AUDCAD',
                    '0.00001',
                    '0.00028',
                    '-5.93',
                    '-1.44',
                    '100,000 AUD',
                    '0',
                ],
                [
                    'AUDCHF',
                    '0.00001',
                    '0.00028',
                    '1.03',
                    '-6.87',
                    '100,000 AUD',
                    '0',
                ],
                [
                    'AUDJPY',
                    '0.001',
                    '0.00028',
                    '5.21',
                    '-13.79',
                    '100,000 AUD',
                    '0',
                ],
                [
                    'AUDNZD',
                    '0.00001',
                    '0.00028',
                    '-10.24',
                    '-1.84',
                    '100,000 AUD',
                    '0',
                ],
                [
                    'AUDUSD',
                    '0.00001',
                    '0.00028',
                    '-4.64',
                    '0.56',
                    '100,000 AUD',
                    '0',
                ],
                [
                    'CADCHF',
                    '0.00001',
                    '0.00028',
                    '3.4',
                    '-9.22',
                    '100,000 CAD',
                    '0',
                ],
                [
                    'CADJPY',
                    '0.001',
                    '0.031',
                    '8.79',
                    '-17.31',
                    '100,000 CAD',
                    '0',
                ],
                [
                    'CHFJPY',
                    '0.001',
                    '0.028',
                    '-2.48',
                    '-12.28',
                    '100,000 CHF',
                    '0',
                ],
                [
                    'CHFSGD',
                    '0.00001',
                    '0.00285',
                    '-31.97',
                    '-5.27',
                    '100,000 CHF',
                    '0',
                ],
            ],
        },
        {
            title: t('trade_74'),
            rows: [
                [
                    'trade_75',
                    'trade_76',
                    'trade_77',
                    'trade_78',
                    'trade_79',
                    'trade_80',
                    'trade_81',
                ],
                [
                    'AAVEUSD',
                    '0.01',
                    '2',
                    '0.01/1000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'ADAUSD',
                    '0.00001',
                    '0.0015',
                    '0.01/3000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'ALGOUSD',
                    '0.00001',
                    '0.006',
                    '0.01/3000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'AVAXUSD',
                    '0.01',
                    '0.12',
                    '0.01/7000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'AXSUSD',
                    '0.01',
                    '0.45',
                    '0.01/1000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'BATUSD',
                    '0.00001',
                    '0.015',
                    '0.01/3000',
                    '2%',
                    '0',
                    '0',
                ],
                [
                    'BCHUSD',
                    '0.01',
                    '1.15',
                    '0.01/460',
                    '0.4%',
                    '0',
                    '0',
                ],
                [
                    'BTCEUR',
                    '0.01',
                    '40',
                    '0.01/30',
                    '0.4%',
                    '0',
                    '0',
                ],
                [
                    'BTCGBP',
                    '0.01',
                    '25',
                    '0.01/30',
                    '0.4%',
                    '0',
                    '0',
                ],
                [
                    'BTCUSD',
                    '0.01',
                    '23',
                    '0.01/80',
                    '0.2%',
                    '0',
                    '0',
                ],
                [
                    'COMPUSD',
                    '0.01',
                    '0.55',
                    '0.01/1600',
                    '2%',
                    '0',
                    '0',
                ],
            ],
        },
        {
            title: 'Stock',
            rows: [
                [
                    'trade_82',
                    'trade_83',
                    'trade_84',
                    'trade_85',
                    'trade_86',
                    'trade_87',
                    'trade_88',
                    'trade_89',
                    'trade_90',
                ],
                [
                    'OMV Group (OMVV.VI)',
                    'OMV',
                    '0.01',
                    '0.42',
                    '0.3/103',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'Voestalpine Group (VOES.VI)',
                    'Voestalpine',
                    '0.01',
                    '0.38',
                    '0.4/148',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'IMMOFINANZ (IMFI.VI)',
                    'Immofinanz',
                    '0.01',
                    '0.17',
                    '0.9/410',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'Andritz AG (ANDR.VI)',
                    'Andritz',
                    '0.01',
                    '0.66',
                    '0.2/87',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'CA Immo (CAIV.VI)',
                    'CAImmobilien',
                    '0.01',
                    '0.36',
                    '0.5/173',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'Oesterreichische Post AG (POST.VI)',
                    'OesterreichischePost',
                    '0.01',
                    '0.45',
                    '0.4/147',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
                [
                    'Telekom Austria Group (TELA.VI)',
                    'TelekomAustria',
                    '0.01',
                    '0.1',
                    '1.7/746',
                    '10%',
                    '-5.9%',
                    '-0.1%',
                    '0',
                ],
            ],
        },
        {
            title: t("trade_91"),
            rows: [
                [
                    'trade_92',
                    'trade_93',
                    'trade_94',
                    'trade_95',
                    'trade_96',
                    'trade_97',
                    'trade_98',
                    'trade_99',
                    'trade_100',
                ],
                [
                    'COCOA',
                    'trade_102',
                    '1',
                    '1 USD',
                    '9',
                    'trade_103',
                    '1/500',
                    '2%',
                    '0',
                ],
                [
                    'COFFEE',
                    'trade_105',
                    '0.0001',
                    '1 USD',
                    '0.005',
                    '10,000 LBS',
                    '1/40',
                    '2%',
                    '0',
                ],
                [
                    'CORN',
                    'trade_107',
                    '0.0001',
                    '0.04 USD',
                    '0.01',
                    'trade_108',
                    '1/400',
                    '2%',
                    '0',
                ],
                [
                    'COTTON',
                    'trade_110',
                    '0.0001',
                    '1 USD',
                    '0.003',
                    '10,000 LBS',
                    '1/100',
                    '2%',
                    '0',
                ],
                [
                    'HGCOP',
                    'trade_112',
                    '0.0001',
                    '0.2 USD',
                    '0.0047',
                    '2,000 LBS',
                    '1/140',
                    '2%',
                    '0',
                ],
                [
                    'SBEAN',
                    'trade_114',
                    '0.0001',
                    '0.04 USD',
                    '0.0185',
                    'trade_115',
                    '1/160',
                    '2%',
                    '0',
                ],
                [
                    'SUGAR',
                    'trade_117',
                    '0.0001',
                    '1 USD',
                    '0.0006',
                    '10,000 LBS',
                    '1/550',
                    '2%',
                    '0',
                ],
            ],
        },
        {
            title: t("trade_118"),
            rows: [
                [
                    'trade_119',
                    'trade_120',
                    'trade_121',
                    'trade_122',
                    'trade_123',
                    'trade_124',
                    'trade_125',
                    'trade_126',
                    'trade_127',
                ],
                [
                    'AUS200Cash',
                    '0.01',
                    '2',
                    '220',
                    '1%',
                    '100',
                    '-1.34',
                    '0.12',
                    '0',
                ],
                [
                    'CA60Cash',
                    '0.01',
                    '0.75',
                    '1080',
                    '0.4%',
                    '250',
                    '-0.25',
                    '0.05',
                    '0',
                ],
                [
                    'China50Cash',
                    '0.01',
                    '7.5',
                    '110',
                    '0.4%',
                    '250',
                    '-2.83',
                    '0.47',
                    '0',
                ],
                [
                    'ChinaHCash',
                    '0.01',
                    '3.3',
                    '550',
                    '0.4%',
                    '250',
                    '-1.07',
                    '-0.06',
                    '0',
                ],
                [
                    'EU50Cash',
                    '0.01',
                    '1.5',
                    '280',
                    '1%',
                    '100',
                    '-0.71',
                    '-0.01',
                    '0',
                ],
                [
                    'FRA40Cash',
                    '0.01',
                    '1',
                    '330',
                    '1%',
                    '100',
                    '-1.23',
                    '-0.02',
                    '0',
                ],
                [
                    'GER40Cash',
                    '0.01',
                    '1.15',
                    '230',
                    '0.2%',
                    '500',
                    '-2.59',
                    '-0.04',
                    '0',
                ],
                [
                    'HK50Cash',
                    '1',
                    '10',
                    '360',
                    '1.5%',
                    '67',
                    '-3.17',
                    '-0.17',
                    '0',
                ],
                [
                    'India50Cash',
                    '0.01',
                    '9',
                    '30',
                    '0.4%',
                    '250',
                    '-3.85',
                    '0.64',
                    '0',
                ],
                [
                    'IT40Cash',
                    '1',
                    '10',
                    '50',
                    '1%',
                    '100',
                    '-4.52',
                    '-0.07',
                    '0',
                ],
                [
                    'JP225Cash',
                    '1',
                    '7',
                    '10000',
                    '0.2%',
                    '500',
                    '-2.37',
                    '-2.38',
                    '0',
                ],
                [
                    'NETH25Cash',
                    '0.01',
                    '0.3',
                    '1500',
                    '1%',
                    '100',
                    '-0.12',
                    '0',
                    '0',
                ],
                [
                    'SA40Cash',
                    '0.01',
                    '25',
                    '110',
                    '0.25%',
                    '400',
                    '-28.72',
                    '16.63',
                    '0',
                ],
            ],
        },
        {
            title: t("trade_128"),
            rows: [
                [
                    'trade_129',
                    'trade_130',
                    'trade_131',
                    'trade_132',
                    'trade_133',
                    'trade_134',
                    'trade_135',
                ],
                [
                    'GOLD',
                    '0.01',
                    '0.25',
                    '-30.67',
                    '16.67',
                    '100 oz',
                    '0',
                ],
                [
                    'SILVER',
                    '0.001',
                    '0.033',
                    '-4.66',
                    '2.5',
                    '5,000 oz',
                    '0',
                ],
                [
                    'XAUEUR',
                    '0.01',
                    '0.35',
                    '19.09',
                    '12.03',
                    '100 oz',
                    '0',
                ],
            ],
        },
        {
            title: t("trade_138"),
            rows: [
                [
                    'trade_139',
                    'trade_140',
                    'trade_141',
                    'trade_142',
                    'trade_143',
                    'trade_144',
                    'trade_145',
                    'trade_146',
                    'trade_147',
                ],
                [
                    'BRENTCash',
                    'trade_148',
                    '0.01',
                    '1 USD',
                    '0.03',
                    'trade_149',
                    '0.01/270',
                    '0.5%',
                    '0',
                ],
                [
                    'NGASCash',
                    'trade_150',
                    '0.001',
                    '1 USD',
                    '0.005',
                    '1,000 MMBtu',
                    '0.01/370',
                    '0.5%',
                    '0',
                ],
                [
                    'OILCash',
                    'trade_151',
                    '0.01',
                    '1 USD',
                    '0.03',
                    'trade_149',
                    '0.01/330',
                    '0.5%',
                    '0',
                ],
            ],
        },
    ];
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt">
            <div className={classes.tabsWrap}>
                <div className={[classes.container, 'container'].join(' ')}>
                    <div className={classes.tabs}>
                        <div className={classes.buttons}>
                            {tabs.map((btn, index) =>
                                <div 
                                    key={index} 
                                    className={`${classes.button} ${index === currentTab && classes.buttonActive} no-select`}
                                    onClick={() => setCurrentTab(index)}
                                >
                                    <span>
                                        {btn.title}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={classes.contentWrap}>
                            <div className={classes.content}>
                                <table>
                                    <tbody>
                                        {tabs[currentTab].rows.map((row, rowIndex) =>
                                            <tr key={rowIndex}>
                                                {row.map((col, colIndex) =>
                                                    <td key={colIndex}>
                                                        <Trans>
                                                            {col}
                                                        </Trans>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tabs;

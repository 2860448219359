import React from "react";
import classes from './ExecutionPolicy.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/TradingPage/ExecutionPolicy/image_01.png';
import image_02 from '../../../assets/img/TradingPage/ExecutionPolicy/image_02.png';
import image_03 from '../../../assets/img/TradingPage/ExecutionPolicy/image_03.png';
import image_04 from '../../../assets/img/TradingPage/ExecutionPolicy/image_04.png';
import image_05 from '../../../assets/img/TradingPage/ExecutionPolicy/image_05.png';

const ExecutionPolicy = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: image_01,
            text: t("trade_152"),
        },
        {
            image: image_02,
            text: t("trade_153"),
        },
        {
            image: image_03,
            text: t("trade_154"),
        },
        {
            image: image_04,
            text: t("trade_155"),
        },
        {
            image: image_05,
            text: t("trade_156"),
        },
    ];
    return (
        <section className="container mt">
            <div className={classes.executionPolicy}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t("trade_157")}
                </h2>
                <p className={[classes.subtitle, 'font-20'].join(' ')}>
                    {t("trade_158")}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img src={card.image} alt=''/>
                            <p className={[classes.text, 'font-20'].join(' ')}>
                                {card.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ExecutionPolicy;

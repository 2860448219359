import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = ({children, login, customLink}) => {
  const lang = Cookies.get('i18next');
  if (customLink) {
    return (
      <a href={customLink} className={`${classes.button}`} target="_blank" without rel="noreferrer">
        <span>{children}</span>
      </a>
    );
  }
  return (
    <>
      {login ?
        <a href="https://user.leverus.pro/login" className={`${classes.button}`}>
          <span>{children}</span>
        </a>
        :
        <a href="https://user.leverus.pro/signup" className={`${classes.button}`}>
          <span>{children}</span>
        </a>
      }
    </>
  );
};

export default Button;

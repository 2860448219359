import React, { useState } from "react";
import classes from "./Faq.module.scss";
import {useTranslation, Trans} from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(0);
      return;
    }
    setCurrentTab(index);
  };
  return (
    <div className={[classes.faq, ""].join(" ")}>
      <section className="mt container">
        <div className={classes.faqWrap}>
          <h2 className={[classes.title, "font-40"].join(" ")}>FAQ</h2>
          <div className={classes.tabs}>
            <div
              className={`${classes.tab} ${
                currentTab === 1 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(1)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_87")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_88")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 2 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(2)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_89")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_90")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 3 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(3)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_91")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_92")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 4 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(4)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_93")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_94")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 5 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(5)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_95")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_96")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 6 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(6)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_97")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_98")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 7 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(7)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_99")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_100")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 8 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(8)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_101")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_102")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 9 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(9)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_103")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_104")}
                </p>
              </div>
            </div>
            <div
              className={`${classes.tab} ${
                currentTab === 10 && classes.tabActive
              } no-select font-20`}
              onClick={() => openTab(10)}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>
                  {t("learn_105")}
                </div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-18">
                  {t("learn_106")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;

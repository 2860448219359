import React from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/AboutPage/2/2.png";
import {useTranslation} from "react-i18next";
const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mt"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <p className={[classes.secondRowLeftText, ""].join(" ")}>{t("about_67")}</p>
              <p className={[classes.secondRowLeftText, ""].join(" ")}>{t("about_68")}</p>
              <p className={[classes.secondRowLeftText, ""].join(" ")}>
                {t("about_4")}
              </p>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;

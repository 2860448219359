import React, { useEffect, useRef, useState } from "react";
import classes from "./Eight.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {useTranslation, Trans} from "react-i18next";
import arrow from "../../../assets/img/AboutPage/8/arrow.gif";

import "swiper/css";
const Eight = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const [items, setItems] = useState([
    { id: 1, active: true, year: "A" },
    { id: 2, active: false, year: "B" },
    { id: 3, active: false, year: "C" },
    { id: 4, active: false, year: "D" },
    { id: 5, active: false, year: "H" },
    { id: 6, active: false, year: "I" },
    { id: 7, active: false, year: "L" },
    { id: 8, active: false, year: "M" },
    { id: 9, active: false, year: "O" },
    { id: 10, active: false, year: "P" },
    { id: 11, active: false, year: "Q" },
    { id: 12, active: false, year: "R" },
    { id: 13, active: false, year: "T" },
    { id: 14, active: false, year: "V" },
    { id: 15, active: false, year: "Y" },
  ]);

  const [content, setContent] = useState([
    {
      id: 1,
      active: true,
      year: "A",
      contents: [
        {
          id: 1,
          title: "learn_30",
          text: "learn_31",
        },
      ],
    },
    {
      id: 2,
      active: false,
      year: "B",
      contents: [
        {
          id: 1,
          title: "learn_32",
          text: "learn_33",
        },
        {
          id: 2,
          title: "learn_34",
          text: "learn_35",
        },
        {
          id: 3,
          title: "learn_36",
          text: "learn_37",
        },
      ],
    },
    {
      id: 3,
      active: false,
      year: "C",
      contents: [
        {
          id: 1,
          title: "learn_38",
          text: "learn_39",
        },
        {
          id: 2,
          title: "learn_40",
          text: "learn_41",
        },
        {
          id: 3,
          title: "learn_42",
          text: "learn_43",
        },
      ],
    },
    {
      id: 4,
      active: false,
      year: "D",
      contents: [
        {
          id: 1,
          title: "learn_44",
          text: "learn_45",
        },
      ],
    },
    {
      id: 5,
      active: false,
      year: "E",
      contents: [
        {
          id: 1,
          title: "learn_46",
          text: "learn_47",
        },
      ],
    },
    {
      id: 6,
      active: false,
      year: "F",
      contents: [
        {
          id: 1,
          title: "learn_48",
          text: "learn_49",
        },
        {
          id: 2,
          title: "learn_50",
          text: "learn_51",
        },
      ],
    },
    {
      id: 7,
      active: false,
      year: "H",
      contents: [
        {
          id: 1,
          title: "learn_52",
          text: "learn_53",
        },
      ],
    },
    {
      id: 8,
      active: false,
      year: "L",
      contents: [
        {
          id: 1,
          title: "learn_54",
          text: "learn_55",
        },
        {
          id: 2,
          title: "learn_56",
          text: "learn_57",
        },
      ],
    },
    {
      id: 9,
      active: false,
      year: "M",
      contents: [
        {
          id: 1,
          title: "learn_58",
          text: "learn_59",
        },
        {
          id: 2,
          title: "learn_60",
          text: "learn_61",
        },
        {
          id: 3,
          title: "learn_62",
          text: "learn_63",
        },
      ],
    },
    {
      id: 10,
      active: false,
      year: "O",
      contents: [
        {
          id: 1,
          title: "learn_64",
          text: "learn_65",
        },
      ],
    },
    {
      id: 11,
      active: false,
      year: "R",
      contents: [
        {
          id: 1,
          title: "learn_66",
          text: "learn_67",
        },
      ],
    },
    {
      id: 12,
      active: false,
      year: "S",
      contents: [
        {
          id: 1,
          title: "learn_68",
          text: "learn_69",
        },
        {
          id: 2,
          title: "learn_70",
          text: "learn_71",
        },
        {
          id: 3,
          title: "learn_72",
          text: "learn_73",
        },
        {
          id: 4,
          title: "learn_74",
          text: "learn_75",
        },
        {
          id: 5,
          title: "learn_76",
          text: "learn_77",
        },
      ],
    },
    {
      id: 13,
      active: false,
      year: "T",
      contents: [
        {
          id: 1,
          title: "learn_78",
          text: "learn_79",
        },
        {
          id: 2,
          title: "learn_80",
          text: "learn_81",
        },
      ],
    },
    {
      id: 14,
      active: false,
      year: "V",
      contents: [
        {
          id: 1,
          title: "learn_82",
          text: "learn_83",
        },
        {
          id: 2,
          title: "learn_84",
          text: "learn_85",
        },
      ],
    },
  ]);
  return (
    <div className={[classes.second, "mt"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.secondTitle, "font-40"].join(" ")}>
            {t("learn_86")}
          </h2>
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[classes.secondRowLeft, ""].join(" ")}
              onClick={() => {
                my_swiper.slidePrev();
              }}
            >
              <img alt="History and milestones" src={arrow} />
            </div>
            <div className={[classes.secondSlider, ""].join(" ")}>
              <Swiper
                onInit={(ev) => {
                  set_my_swiper(ev);
                }}
                slidesPerView={4}
                breakpoints={{
                  993: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  100: {
                    slidesPerView: 2,
                  },
                }}
              >
                {items.map((i) => {
                  return (
                    <SwiperSlide>
                      <div
                        key={i.id}
                        className={
                          i.active
                            ? [
                                classes.secondSliderSlide,
                                classes.active,
                                "",
                              ].join(" ")
                            : [classes.secondSliderSlide, ""].join(" ")
                        }
                        onClick={() => {
                          setItems(
                            items.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                          setContent(
                            content.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                        }}
                      >
                        <div className={classes.secondSliderSlideCircle}></div>
                        <p
                          className={[
                            classes.secondSliderSlideText,
                            "font-20",
                          ].join(" ")}
                        >
                          {i.year}
                        </p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div
              className={[classes.secondRowRight, ""].join(" ")}
              onClick={() => {
                my_swiper.slideNext();
              }}
            >
              <img alt="History and milestones" src={arrow} />
            </div>
          </div>
          {content.map((contentI) => {
            if (contentI.active) {
              return (
                <div
                  className={[classes.secondContent, ""].join(" ")}
                  key={contentI.id}
                >
                  <h2
                    className={[classes.secondContentYear, "font-40"].join(" ")}
                  >
                    {contentI.year}
                    <span STYLE="text-transform:lowercase">
                      {contentI.year}
                    </span>
                  </h2>
                  {contentI.contents.map((subI) => {
                    return (
                      <div>
                        <p
                          className={[
                            classes.secondContentTitle,
                            "font-25",
                          ].join(" ")}
                        >
                          <Trans>{subI.title}</Trans>
                        </p>
                        <p
                          className={[
                            classes.secondContentText,
                            "font-20",
                          ].join(" ")}
                        >
                          <Trans>{subI.text}</Trans>
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Eight;

import logo from '../assets/img/logo.png';
// home
import img1 from '../assets/img/HomePage/1/1/1.png';
import img2 from '../assets/img/HomePage/1/1/2.png';
import img3 from '../assets/img/HomePage/1/1/3.png';
import img4 from '../assets/img/HomePage/1/1/4.png';
import img5 from '../assets/img/HomePage/1/1/5.png';
// trading
import img6 from '../assets/img/TradingPage/Banner/bg.png';
import img7 from '../assets/img/TradingPage/Banner/image_01.png';
import img8 from '../assets/img/TradingPage/Banner/image_02.png';
import img9 from '../assets/img/TradingPage/Banner/image_03.png';
import img10 from '../assets/img/TradingPage/UnderBanner/image_01.png';
import img11 from '../assets/img/TradingPage/UnderBanner/image_02.png';
import img12 from '../assets/img/TradingPage/UnderBanner/image_03.png';
import img13 from '../assets/img/TradingPage/UnderBanner/image_04.png';
import img14 from '../assets/img/TradingPage/UnderBanner/image_05.png';
// learn to trade
import img16 from '../assets/img/LearnPage/1/2.png';
import img17 from '../assets/img/LearnPage/1/3.png';
import img18 from '../assets/img/LearnPage/1/4.png';
// promotions
import img19 from '../assets/img/PromotionsPage/Banner/image_01.png';
import img20 from '../assets/img/PromotionsPage/Banner/image_02.png';
// faq
import img21 from '../assets/img/FaqPage/Banner/item_01.png';
import img22 from '../assets/img/FaqPage/Banner/item_02.png';
import img23 from '../assets/img/FaqPage/Banner/item_03.png';
import img24 from '../assets/img/FaqPage/Banner/man.png';
import img25 from '../assets/img/FaqPage/Banner/arrow.png';
// about
import img26 from '../assets/img/AboutPage/1/1.png';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
];

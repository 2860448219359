import React from "react";
import classes from './LegendaryExecution.module.scss';
import { useTranslation } from "react-i18next";
import cubes from '../../../assets/img/TradingPage/LegendaryExecution/cubes.png';
import item_01 from '../../../assets/img/TradingPage/LegendaryExecution/item_01.png';
import item_02 from '../../../assets/img/TradingPage/LegendaryExecution/item_02.png';
import item_03 from '../../../assets/img/TradingPage/LegendaryExecution/item_03.png';

const LegendaryExecution = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.legendaryExecution}>
                <div className={[classes.content, 'font-20'].join(' ')}>
                    <h4 className={classes.title}>
                        {t("trade_159")}
                    </h4>
                    <p>
                        {t("trade_160")}
                    </p>
                    <h4 className={classes.title}>
                        {t("trade_161")}
                    </h4>
                    <p>
                        {t("trade_162")}
                    </p>
                    <p>
                        {t("trade_163")}
                    </p>
                    <p>
                        {t("trade_164")}
                    </p>
                    <p>
                        {t("trade_165")}
                    </p>
                </div>
                <div className={classes.images}>
                    <img className={classes.cubes} src={cubes} alt=''/>
                    <div className={classes.item_01}>
                        <img src={item_01} alt=''/>
                    </div>
                    <div className={classes.item_02}>
                        <img src={item_02} alt=''/>
                    </div>
                    <div className={classes.item_03}>
                        <img src={item_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LegendaryExecution;

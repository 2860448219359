import React from "react";
import classes from './HowWorks.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/PromotionsPage/HowWorks/image.png';
import bg from '../../../assets/img/PromotionsPage/HowWorks/bg.png';

const HowWorks = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.howWorks}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t("promo_52")}
                    </h2>
                    <ul className="font-20">
                        <li>
                            {t("promo_53")}
                        </li>
                        <li>
                            {t("promo_54")}
                        </li>
                        <li>
                            {t("promo_55")}
                        </li>
                        <li>
                            {t("promo_56")}
                        </li>
                    </ul>
                </div>
                <img className={classes.image} src={image} alt=''/>
                <img className={classes.bg} src={bg} alt=''/>
            </div>
        </section>
    );
};

export default HowWorks;

import React from "react";
import { useTranslation, Trans } from "react-i18next";
import classes from "./Second.module.scss";
const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mt"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <h3 className={[classes.secondRowLeftTitle, "font-40"].join(" ")}>
                <Trans>learn_4</Trans>
              </h3>
              <p className={[classes.secondRowLeftText, "font-20"].join(" ")}>
                {t("learn_5")}
              </p>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <h3
                className={[classes.secondRowRightTitle, "font-40"].join(" ")}
              >
                <Trans>learn_6</Trans>
              </h3>
              <p className={[classes.secondRowRightText, "font-20"].join(" ")}>
                {t("learn_7")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation, Trans } from "react-i18next";
import arrow from '../../../assets/img/FaqPage/Banner/arrow.png';
import man from '../../../assets/img/FaqPage/Banner/man.png';
import item_01 from '../../../assets/img/FaqPage/Banner/item_01.png';
import item_02 from '../../../assets/img/FaqPage/Banner/item_02.png';
import item_03 from '../../../assets/img/FaqPage/Banner/item_03.png';

const Banner = ({ active, setActive }) => {
    const { t } = useTranslation();
    const titles = [
        'promo_73',
        'promo_74',
        'promo_75',
        'promo_76',
        'promo_77',
        'promo_78',
    ];
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-40'].join(' ')}>
                            {t("promo_79")}
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t("promo_80")}
                        </p>
                        <ul>
                            {titles.map((title, index) =>
                                <li 
                                    key={index}
                                    onClick={() => setActive(index)}
                                    className={`${index === active && classes.liActive}`}
                                >
                                    <img className={classes.arrow} src={arrow} alt=''/>
                                    <p className="font-20 no-select">
                                        <Trans>{title}</Trans>
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={classes.images}>
                        <img className={classes.man} src={man} alt=''/>
                        <img className={[classes.item, classes.item_01].join(' ')} src={item_01} alt=''/>
                        <img className={[classes.item, classes.item_02].join(' ')} src={item_02} alt=''/>
                        <img className={[classes.item, classes.item_03].join(' ')} src={item_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;

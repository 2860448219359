import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/AboutComponents/First/First";
import Second from "../../components/AboutComponents/Second/Second";
import SixReasons from "../../components/AboutComponents/SixReasons/SixReasons";
import Three from "../../components/AboutComponents/Three/Three";
import Four from "../../components/AboutComponents/Four/Four";
import Five from "../../components/AboutComponents/Five/Five";
import Six from "../../components/AboutComponents/Six/Six";
import Test from "../../components/AboutComponents/Test/Slider";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>About Us</title>
        <meta
          name="title"
          content="Discover Leverus Forex Broker's Story"
        />
        <meta
          property="og:title"
          content="Discover Leverus Forex Broker's Story"
        />
        <meta
          property="twitter:title"
          content="Discover Leverus Forex Broker's Story"
        />

        <meta
          name="description"
          content="Learn more about Leverus's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."
        />
        <meta
          property="og:description"
          content="Learn more about Leverus's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."
        />
        <meta
          property="twitter:description"
          content="Learn more about Leverus's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <SixReasons />
        <Three />
        <Four />
        <Five />
        <Six />
        <Test />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;

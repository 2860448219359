import React from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/LearnPage/3/1.png";
import img2 from "../../../assets/img/LearnPage/3/2.png";
import img3 from "../../../assets/img/LearnPage/3/3.png";
import img4 from "../../../assets/img/LearnPage/3/4.png";
import {useTranslation, Trans} from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, "mt"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-40"].join(" ")}>
            <Trans>learn_22</Trans>
          </h2>
          <p className={[classes.threeSubtitle, "font-20"].join(" ")}>
            {t("learn_23")}
          </p>
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <div className={[classes.threeRowLeftItem, ""].join(" ")}>
                <h3
                  className={[classes.threeRowLeftItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img1} alt="Resources for Learning Trading" />
                  </div>
                  {t("learn_24")}
                </h3>
                <p
                  className={[classes.threeRowLeftItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("learn_25")}
                </p>
              </div>
              <div className={[classes.threeRowLeftItem, ""].join(" ")}>
                <h3
                  className={[classes.threeRowLeftItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img2} alt="Resources for Learning Trading" />
                  </div>
                  {t("learn_26")}
                </h3>
                <p
                  className={[classes.threeRowLeftItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("learn_27")}
                </p>
              </div>
              <div className={[classes.threeRowLeftItem, ""].join(" ")}>
                <h3
                  className={[classes.threeRowLeftItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img3} alt="Resources for Learning Trading" />
                  </div>
                  {t("learn_28")}
                </h3>
                <p
                  className={[classes.threeRowLeftItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("learn_29")}
                </p>
              </div>
            </div>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <img src={img4} alt="Resources for Learning Trading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import classes from "./Three.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/HomePage/3/1.png";
const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, "mt"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <h2 className={[classes.threeRowLeftTitle, "font-40"].join(" ")}>
                <Trans>main_21</Trans>
              </h2>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t("main_22")}
              </p>
              <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                <Button>{t("btn_open")}</Button>
              </div>
            </div>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;

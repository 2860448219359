import React, { useState, useEffect } from "react";
import classes from './Schedule.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import axios from "axios";

const rows = [
    {
        col_01: 'education_text_01_row_01',
        col_02: '16:30',
    },
    {
        col_01: 'education_text_01_row_02',
        col_02: '17:30',
    },
    {
        col_01: 'education_text_01_row_03',
        col_02: '18:30',
    },
];

const Schedule = () => {
    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    const [ webinarLink, setWebinarLink ] = useState('#');

    const getSheetValues = async () =>{
        try {
            const { data } = await axios.post('https://civbt.xyz/api/bot/pEoOWPNFOo');
            if (data.status === 200) {
                setWebinarLink(data.data);
                return;
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (domLoaded) {
            getSheetValues();
        }
        // eslint-disable-next-line
    }, [ domLoaded ]);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return (
        <section className="mt container">
            <div className={classes.schedule}>
                <h2 className={`${classes.title} font-40`}>
                    {t('education_title_04')}
                </h2>
                <div className={classes.tableWrap}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {t('education_text_01_col_01')}
                                </td>
                                <td>
                                    {t('education_text_01_col_02')}
                                </td>
                            </tr>
                            {rows.map((row, rowIndex) =>
                                <tr key={rowIndex}>
                                    <td>
                                        {t(row.col_01)}
                                    </td>
                                    <td>
                                        {row.col_02}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={classes.btn}>
                    <Button customLink={webinarLink}>
                        {t('education_take_part')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Schedule;

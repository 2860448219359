import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import { useState } from "react";
import Banner from "../../components/FaqPage/Banner/Banner";
import Faq from "../../components/FaqPage/Faq/Faq";
import NeedHelp from "../../components/FaqPage/NeedHelp/NeedHelp";

const FaqPage = () => {
  const [ active, setActive ] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>FAQ & Support</title>
        <meta name="title" content="Find Answers to Your Questions with Leverus Support" />
        <meta property="og:title" content="Find Answers to Your Questions with Leverus Support" />
        <meta property="twitter:title" content="Find Answers to Your Questions with Leverus Support" />

        <meta name="description" content="Get fast and accurate answers to your trading-related questions with Leverus's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need." />
        <meta property="og:description" content="Get fast and accurate answers to your trading-related questions with Leverus's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need." />
        <meta property="twitter:description" content="Get fast and accurate answers to your trading-related questions with Leverus's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner active={active} setActive={setActive}/>
        <Faq active={active} setActive={setActive}/>
        <NeedHelp />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;

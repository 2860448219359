import React, { useRef } from "react";
import classes from "./ChooseAccount.module.scss";

import arrow from "../../../assets/img/AccountTypes/ChooseAccount/arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import img1 from "../../../assets/img/LearnPage/2/1.png";
import img2 from "../../../assets/img/LearnPage/2/2.png";
import img3 from "../../../assets/img/LearnPage/2/3.png";
import img4 from "../../../assets/img/LearnPage/2/4.png";
import img5 from "../../../assets/img/LearnPage/2/5.png";
import img6 from "../../../assets/img/LearnPage/2/6.png";
import "swiper/css";
import "swiper/css/pagination";
import {useTranslation, Trans} from "react-i18next";

const ChooseAccount = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 1,
      title: "learn_8",
      img: img1,
      text: "learn_9",
    },
    {
      id: 2,
      img: img2,
      title: "learn_10",
      text: "learn_11",
    },
    {
      id: 3,
      img: img3,
      title: "learn_12",
      text: "learn_13",
    },
    {
      id: 4,
      img: img4,
      title: "learn_14",
      text: "learn_15",
    },
    {
      id: 5,
      img: img6,
      title: "learn_18",
      text: "learn_19",
    },
  ];
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <section className={classes.chooseAccount}>
      <div className="container">
        <div className={[classes.fiveTop, ""].join(" ")}>
          <h2 className={[classes.fiveTopTitle, "font-40"].join(" ")}>
            <Trans>learn_20</Trans>
          </h2>
        </div>
      </div>
      <div className={classes.swiperWr}>
        <Swiper
          modules={[Pagination, Navigation]}
          className="chooseAccountTypeSwiper"
          spaceBetween={20}
          slidesPerView={"auto"}
          pagination={true}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {cards.map((card) => (
            <SwiperSlide key={card.id} className="chooseAccountTypeSwiperSlide">
              <div className={classes.card}>
                <div className={[classes.cardImgCustom, ""].join(" ")}>
                  <img
                    src={card.img}
                    alt="Choose a Big, Fair and Human Broker"
                  />
                </div>

                <div className={classes.cardContent}>
                  <h3 className={[classes.cardTitle, "font-20"].join(" ")}>
                    <Trans>{card.title}</Trans>
                  </h3>

                  <p className={[classes.cardText, "font-20"].join(" ")}>
                    <Trans>{card.text}</Trans>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container">
        <div className={classes.pagination}>
          <img ref={prevRef} className={classes.pagPrev} src={arrow} alt="" />
          <img ref={nextRef} className={classes.pagNext} src={arrow} alt="" />
        </div>
      </div>
    </section>
  );
};

export default ChooseAccount;

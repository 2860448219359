import React from "react";
import classes from "./Five.module.scss";
import img1 from "../../../assets/img/AboutPage/6/1.png";
import {useTranslation, Trans} from "react-i18next";
const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, "mt"].join(" ")}>
      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fiveRow, ""].join(" ")}>
            <div className={[classes.fiveRowLeft, ""].join(" ")}>
              <h2 className={[classes.fiveRowLeftTitle, "font-40"].join(" ")}>
                <Trans>about_23</Trans>
              </h2>
              <p className={[classes.fiveRowLeftText, "font-20"].join(" ")}>
                {t("about_24")}
              </p>
              <p className={[classes.fiveRowLeftText, "font-20"].join(" ")}>
                {t("about_25")}
              </p>
            </div>
            <div className={[classes.fiveRowRight, ""].join(" ")}>
              <img src={img1} alt="Human Company" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
